<template>
  <div class="previewTable">
    <slot name="center"></slot>
    <div class="secondPart">
      <el-form
        :validate-on-rule-change="true"
        :model="countryForm"
        ref="countryRef"
        :rules="countryRules"
        :inline="true"
        label-width="108px"
      >
        <el-table :data="fileListData" border style="width: 100%">
          <el-table-column
            align="center"
            v-for="item in propList"
            :key="item.id"
            :prop="`${item.prop}`"
            :label="`${item.label}`"
            width="180"
          >
            <template slot-scope="scope">
              <el-form-item ref="elFormItem" :prop="`${item.prop}_${scope.$index}`" :rules="countryRules[item.prop]">
                <el-input
                  @blur="onBlur"
                  size="mini"
                  v-model="countryForm[`${item.prop}_${scope.$index}`]"
                  style="width: 120px"
                ></el-input>
              </el-form-item> </template
          ></el-table-column>
        </el-table>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getList } from '@/api/currency'
import { verifyCn, verifyEn, verifyCode, verifyState, verifyType, propList } from '../validate/validate.js'

export default {
  props: {
    fileListData: {
      type: Array,
      required: true
    },
    errData: {
      type: Object
    },
    countryForm: {
      type: Object
    },
    state: {
      type: Boolean
    }
  },
  data() {
    var verifyCurrency = async (rule, value, callback) => {
      if (!this.currency.includes(value)) {
        callback(new Error('请正确填写币种'))
      } else {
        callback()
      }
      callback()
    }
    return {
      currency: [],
      propList,
      countryRules: {
        countryCnName: [
          { required: true, message: '请填写中文名称', trigger: 'blur' },
          { validator: verifyCn, trigger: 'blur' }
        ],
        countryEnName: [
          { required: true, message: '请填写英文名称', trigger: 'blur' },
          { validator: verifyEn, trigger: 'blur' }
        ],
        twoCharCode: [
          { required: true, message: '请填写二位码', trigger: 'blur' },
          { validator: verifyCode, trigger: 'blur' }
        ],
        continentDictCode: [
          { required: true, message: '请填写所属洲名称', trigger: 'blur' },
          { validator: verifyState, trigger: 'blur' }
        ],
        currency: [
          { required: true, message: '请填写币种', trigger: 'blur' },
          { validator: verifyCurrency, trigger: 'blur' }
        ],
        prepaymentType: [
          { required: true, message: '请填写税号', trigger: 'blur' },
          { validator: verifyType, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    statusInfo({ errData: { failCount }, fileListData }) {
      const uploadedCount = fileListData.length
      return {
        uploadedCount,
        successCount: uploadedCount - failCount,
        failCount
      }
    },
    errKey({ errData: { errKey } }) {
      return errKey
    }
    // countryRules() {
    //   console.log('dasdsa', item)
    //   return (item) => {
    //     return item.prop
    //   }
    // }
  },
  methods: {
    onBlur() {
      this.$nextTick(() => {
        const { elFormItem } = this.$refs
        const validateStateArr = elFormItem.map((item) => {
          return item.validateState
        })
        if (validateStateArr.some((state) => state === 'error')) {
          this.$emit('update:state', true)
          return
        }
        this.$emit('update:state', false)
      })
    }
    // tets() {
    //   const form = {
    //     a_0: 'a_0',
    //     b_0: 'b_0',
    //     a_1: 'a_1',
    //     b_1: 'b_1'
    //   }
    //   const tmp = []
    //   for (let key in form) {
    //     const [fKey, num] = key.split('_')
    //     console.log('111', key.split('_'))
    //     if (!tmp[num]) {
    //       tmp[num] = {}
    //     }
    //     tmp[num][fKey] = form[key]
    //   }
    //   console.log('tmp', tmp)
    // }
  },
  async created() {
    const data = {
      pageSize: 1000
    }
    await getList(data).then((res) => {
      if (!res.detail) return
      this.currency = res.detail.map((item) => item.currencyName)
    })
  }
}
</script>

<style scoped lang="scss">
.firstPart {
  height: 50px;
  min-width: 460px;

  span {
    display: inline-block;
    width: 25%;
    line-height: 50px;

    i {
      color: orange;
    }
  }
}

.secondPart {
  border: 1px solid rgba($color: #000000, $alpha: 0.2);
}

.failed {
  border: 1px solid $color-danger;
}
</style>
