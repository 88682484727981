var verifyCn = (rule, value, callback) => {
  if (/^[\u4e00-\u9fa5()（）-]{0,}$/.test(value) == false) {
    callback(new Error('请输入中文'))
  } else {
    callback()
  }
  callback()
}
var verifyEn = (rule, value, callback) => {
  if (/^[A-Za-z .,。，()（）/ ]+$/.test(value) == false) {
    callback(new Error('请输入英文'))
  } else {
    callback()
  }
  callback()
}
var verifyCode = (rule, value, callback) => {
  if (/^[A-Za-z]{2}$/.test(value) == false) {
    callback(new Error('请输入两位英文'))
  } else {
    callback()
  }
  callback()
}
var verifyState = (rule, value, callback) => {
  if (!['亚洲', '欧洲', '非洲', '美洲', '大洋洲'].includes(value)) {
    callback(new Error('请正确填写洲名称'))
  } else {
    callback()
  }
  callback()
}
var verifyType = (rule, value, callback) => {
  if (!['IOSS税号', '英国税号', '否'].includes(value)) {
    callback(new Error('请正确填写税号'))
  } else {
    callback()
  }
  callback()
}

var propList = [
  { id: 0, prop: 'countryCnName', label: '国家/地区中文名称' },
  { id: 1, prop: 'countryEnName', label: '国家/地区英文名称' },
  { id: 2, prop: 'twoCharCode', label: '国家/地区两位代码' },
  { id: 3, prop: 'continentDictCode', label: '所属洲' },
  { id: 4, prop: 'prepaymentType', label: '是否需要填写税号' },
  { id: 5, prop: 'currency', label: '报关金额币种' }
]

export { verifyCn, verifyEn, verifyCode, verifyState, verifyType, propList }
