import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

//新增货币数据
export function create(data) {
  return request({
    url: '/externaladmin/systemService/currency/create',
    method: 'post',
    data
  })
}

//获取货币数据列表
export function getList(data, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/systemService/currency/list',
    method: 'post',
    data
  })
}

//编辑货币数据列表
export function edit(data) {
  return request({
    url: '/externaladmin/systemService/currency/update',
    method: 'post',
    data
  })
}

//删除货币数据列表
export function del(data) {
  return request({
    url: '/externaladmin/systemService/currency/delete',
    method: 'post',
    data
  })
}
